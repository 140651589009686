import {useTranslation} from 'react-i18next';
import {useEffect, useState} from "react";
import axios, {create} from "axios";

function CreateChangeOtpUrl(realmUrl) {
  const redirectUrl = window.location.href;
  const returnUrlEncoded = encodeURIComponent(redirectUrl);
  return realmUrl + "/protocol/openid-connect/auth?response_type=code&client_id=recon-account&kc_action=CONFIGURE_TOTP&scope=openid&redirect_uri=" + returnUrlEncoded; 
}

function CreateChangePasswordUrl(realmUrl) {
  const redirectUrl = window.location.href;
  const returnUrlEncoded = encodeURIComponent(redirectUrl);
  return realmUrl + "/protocol/openid-connect/auth?response_type=code&client_id=recon-account&kc_action=UPDATE_PASSWORD&scope=openid&redirect_uri=" + returnUrlEncoded;
}

export default function Security() {
  const { t } = useTranslation();
  
  const [changeOtpUrl, setChangeOtpUrl] = useState("");
  const [changePasswordUrl, setChangePasswordUrl] = useState("");
  const [credentials, setCredentials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

    const credentialsUrl = "/realms/reconhub/account/credentials";
    const configurationUrl = "/configuration";
    
    const init = async () => {
        try {
            const response = await axios.get(credentialsUrl);
            // Destructure the relevant properties from the response data
            const data = response.data;
            setCredentials(data);
            const config = await axios.get(configurationUrl);
            const realmUrl = config.data.realmUrl;
            setChangeOtpUrl(CreateChangeOtpUrl(realmUrl));
            setChangePasswordUrl(CreateChangePasswordUrl(realmUrl));
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        // Fetch user data from the API
        init();
    }, []);
  
    const removeCredential = async (id) => {
        try {
            const response = await axios.delete(credentialsUrl + "/" + id);
            init();
        } catch (err) {
            console.log(err);
        }
    };
    
    if (loading) {
        return null;
    }

    if (error) {
        return (
            <>
                <div className="alert">
                    <span className="kc-feedback-text">{t('genericError')}</span>
                </div>
            </>)
    }
    
  return (
      <>
          <div className="page-title">
            {t('accountSecurity')}
          </div>
          <div className="account-security-container">
              <div className="form-horizontal">
                  <h2 className="account-security-title">{t('password')}</h2>
                  <p className="account-security-description">{t('passwordDescription')}</p>
                  <div>
                      <a href={changePasswordUrl} className="account-security-link">{t('changePassword')}</a>
                  </div>
              </div> 
                  <div className="form-horizontal">
                      <h2 className="account-security-title">{t('mfa')}</h2>
                      <p className="account-security-description">{t('mfaDescription')}</p>
                      {credentials?.find(x => x.type === "otp")?.userCredentialMetadatas?.length === 0 && <a href={changeOtpUrl} className="account-security-link">{t('setMfa')}</a>}
                      <div className="token-container">
                          {credentials?.find(x => x.type === "otp")?.userCredentialMetadatas?.length !== 0 && <p className="token-container-header">{t('connectedDevice')}</p>}
                          {credentials && credentials?.find(x => x.type === "otp")?.userCredentialMetadatas.map((credentialMetadata) => (
                            <div className="token-instance">
                              <p className="account-security-description">{credentialMetadata.credential.userLabel}</p>
                              <div className="form-inline">
                                  <button className="account-security-link" onClick={() => removeCredential(credentialMetadata.credential.id)}>
                                  {t('removeOtp')}
                                  </button>
                                </div>
                            </div>
                          ))}
                        </div>
                  </div>
          </div>
      </>
  );
}