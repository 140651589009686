import { useTranslation } from 'react-i18next';
import Dropdown from '../components/Dropdown';
import axios from "axios";
import {useEffect, useState} from "react";
import i18n from "i18next";
export default function Account() {
    const { t, i18n } = useTranslation();

    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState("");
    
    const accountUrl = "/realms/reconhub/account";

    const fetchUserData = async () => {
        try {
            const response = await axios.get(accountUrl);
            // Destructure the relevant properties from the response data
            const data = response.data;
            setUserData(data);
            const locale = data.attributes?.locale[0];
            if (locale) {
                await i18n.changeLanguage(locale);
            }
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        // Fetch user data from the API
        fetchUserData();
    }, []);
    
    const handleLocaleChange = (newLocale) => {
        setUserData((prevState) => {
            return {
                ...prevState,
                attributes: {
                    ...prevState.attributes,
                    locale: [newLocale]
                }
            };
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevState) => {{
                return {
                    ...prevState,
                    [name]: value
                };
            }
        });
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(accountUrl, userData);
            console.log('Data submitted successfully.', response.data);
            setMessage( (_) => {
                return t('accountUpdatedSuccess');
            });
            setTimeout(() => setMessage(""), 5000);
        } catch (error) {
            console.error('Error submitting data.', error);
            setMessage( (_) => {
                return t('accountUpdatedError');
            });
            setTimeout(() => setMessage(""), 5000);
        }
    };
    
    const options = [
        "en",
        "de",
        "fr"
    ];
    
    if (loading)
    {
        return null;
    }
    
    if (error) {
        return (
        <>
            <div className="alert">
                <span className="kc-feedback-text">{t('genericError')}</span>
            </div>
        </>)
    }
    return (
        <>
            <div className="alert">
                <span className="kc-feedback-text">{message}</span>
            </div>
            <div className="page-title">
                {t('personalDetails')}
            </div>
            <div className="form-horizontal">
                <div className="relative w-full h-[56px] px-m py-xs inline-flex flex-col-reverse gap-y-2xs
                          bg-white-100 border outline-none text-left
                          border-blue.0-100
                          focus-within:border-blue.3-100">
                    <input
                        className="w-full outline-none ring-0 caret-blue.3-100 text-body2 peer"
                        name="firstName"
                        type="text"
                        id="firstName"
                        value={userData.firstName ?? ""}
                        onChange={handleChange}
                        />
                    <span className="text-blue.0-50 block text-caption2 font-sans peer-focus:text-blue.3-100">{t('firstName')}</span>
                </div>
                <div className="relative w-full h-[56px] px-m py-xs inline-flex flex-col-reverse gap-y-2xs
                          bg-white-100 border outline-none text-left
                          border-blue.0-100
                          focus-within:border-blue.3-100">
                    <input
                        className="w-full outline-none ring-0 caret-blue.3-100 text-body2 peer"
                        name="lastName"
                        type="text"
                        id="lastName"
                        value={userData.lastName ?? ""}
                        onChange={handleChange}
                        />
                    <span className="text-blue.0-50 block text-caption2 font-sans peer-focus:text-blue.3-100">{t('lastName')}</span>
                </div>
                <Dropdown 
                    translate={t}
                    currentLang={userData.attributes?.locale[0]}
                    options={options}
                    i18n={i18n}
                    onChange={handleLocaleChange}
                />
                <div className="relative w-full h-[56px] px-m py-xs inline-flex flex-col-reverse gap-y-2xs
                          bg-white-100 border outline-none text-left
                          border-blue.0-100
                          focus-within:border-blue.3-100 readonly">
                    <input
                        className="w-full outline-none ring-0 caret-blue.3-100 text-body2 peer readonly-input"
                        name="email"
                        type="text"
                        id="email"
                        defaultValue={userData.email ?? ""}
                        readOnly={true}
                        />
                    <span className="text-blue.0-50 block text-caption2 font-sans peer-focus:text-blue.3-100">{t('email')}</span>
                </div>
                <div id="kc-form-buttons" className="buttons submit">
                        <div>
                            <button
                                type="submit"
                                className="button primary"
                                name="submitAction"
                                value="Save"
                                onClick={handleSubmit}
                            >
                                {t('doSave')}
                            </button>
                            <button
                                type="submit"
                                className="button secondary"
                                name="submitAction"
                                value="Cancel"
                                onClick={fetchUserData}
                            >
                                {t('doCancel')}
                            </button>
                        </div>
                </div>
            </div>
        </>
    );
}
