import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./pages/Layout";
import Account from "./pages/Account";
import Security from "./pages/Security";
import PageNotFound from "./pages/PageNotFound";
import "./i18n";
import {AuthRoute, logout} from "./Auth";
import {useEffect, useState} from "react";
import axios from "axios";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

export default function App() {

    const { t, i18n } = useTranslation();
    
    const accountUrl = "/realms/reconhub/account";
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // Fetch the data when the component mounts
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(accountUrl);
                // Destructure the relevant properties from the response data
                const data = response.data;
                const locale = data.attributes?.locale[0];
                if (locale) {
                    await i18n.changeLanguage(locale);
                }
            } catch (err) {
                logout();
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);
    
    if (loading) {
        return null;
    }

    if (error) {
        return (
            <>
                <div className="alert">
                    <span className="kc-feedback-text">{t('genericError')}</span>
                </div>
            </>)
    }
    
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<AuthRoute> <Layout /> </AuthRoute>}>
          <Route index element={<Account />} />
          <Route path="account" element={<Account />} />
          <Route path="security" element={<Security />} />
          <Route path="*" element={<PageNotFound
           />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
