import React, { useState, useRef, useEffect } from 'react';
import "../reconhub.css";
function Dropdown ({ translate, currentLang, options, i18n, onChange }) {

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full h-[56px] px-m py-xs inline-flex flex-col-reverse gap-y-2xs
                        bg-white-100 border outline-none text-left
                        border-blue.0-100
                        focus-within:border-blue.3-100 pointer" id="kc-locale-dropdown" onClick={toggleDropdown}>
        <p className="w-full outline-none ring-0 caret-blue.3-100 text-body2 peer">
            {translate(currentLang)}
        </p>
        <span className="text-blue.0-50 block text-caption2 font-sans peer-focus:text-blue.3-100">{translate("language")}</span>
      {isOpen && (
        <div className="languages-container">
          {options.map((option) => (
            <div
              key={option}
              className="language-selection"
              onClick={() => {
                // Handle option click
                i18n.changeLanguage(option);
                setIsOpen(false);
                onChange(option);
              }}
            >
              <span className="language-text">{translate(option)}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;