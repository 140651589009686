import { Outlet, Link } from "react-router-dom";
import reconhubLogoUrl from "../assets/reconhub-logo.svg";
import abrantixLogoUrl from "../assets/abrantix-logo.svg";
import personalDetails from "../assets/personal-details.svg";
import accountSecurity from "../assets/account-security.svg";
import "../reconhub.css";
import { useTranslation, Trans } from 'react-i18next';
import {logout} from "../Auth";


function Layout() {
  const { t } = useTranslation();

  const accountUrl = "/account";
  const securityUrl = "/security";
  return (
    <>
        <div className="container">
            <div className="sidebar">
                <div className="logo-and-links">
                    <div className="logo-container">
                        <img src={reconhubLogoUrl} />
                    </div>
                    <p className="link-header">{t('menu')}</p>
                    <ul className="links">
                        <li className="link-container">
                            <img src={personalDetails}/>
                            <Link className="link" to={accountUrl}>{t('personalDetails')}</Link>
                        </li>
                        <li className="link-container">
                            <img src={accountSecurity}/>
                            <Link className="link"  to={securityUrl}>{t('security')}</Link>
                        </li>
                    </ul>
                </div>

                <div className="logOutContainer">
                    <button className="logout" onClick={() => logout()}>{t('doSignOut')}</button>
                </div>
            </div>
            <div className="content-container">
                <div className="content-message-container">
                    <Outlet />
                </div>
                
                <div className="abrantix-container">
                        <img className="abrantix-logo" src={abrantixLogoUrl} />
                </div>
            </div>
        </div>
    </>
);
}

export default Layout;
