import React, { useState, useEffect } from 'react';

function checkIsAuthenticated() {
    return fetch('/auth/user', {
        method: 'GET',
        credentials: 'include',
    })
        .then(response => response.json())
        .then(data => data.isAuthenticated)
        .catch(() => false);
}

export function logout() {
    const redirectUrl = window.location.href;
    window.location.href = '/auth/logout?redirectUri=' + encodeURIComponent(redirectUrl);
}

export const AuthRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        checkIsAuthenticated().then(authStatus => {
            if (!authStatus) {
                const currentUrl = window.location.href;
                window.location.href = `/auth/login?redirectUri=${encodeURIComponent(currentUrl)}`;
            }
            else {
                setIsAuthenticated(authStatus);
            }
        });
    }, []);

    if (isAuthenticated === null) {
        return <div></div>; // or any loading indicator
    }

    return isAuthenticated ? children : null;
};
